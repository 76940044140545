'use strict'

import axios from 'axios'
import authService from '../services/Commons/AuthService'
import Store from '../store'
import Router from '../router'
import https from 'https'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_BASE_API_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: true,
  }),
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    Store.commit('setLoading', true)
    console.log('entrando request')

    // Do something before request is sent
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
    }

    try {
      config.headers.ClientDateTimeISO = new Date().toISOString()
      var tzoffset = (new Date()).getTimezoneOffset() * 60000 // offset in milliseconds
      config.headers.ClientDateTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1)
      config.headers['x-is-wd'] = window.navigator.webdriver === undefined ? false : window.navigator.webdriver
    } catch {

    }

    return config
  },
  function (error) {
    Store.commit('setLoading', false)

    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    Store.commit('setLoading', false)
    // Do something with response data
    return response
  },
  async function (error) {
    // Do something with response error
    const originalRequest = error.config
    if (error.response.status === 401) {
      try {
        const grecaptcha = await this.$recaptcha('login')
        const response = (
          await authService.login({
            refreshToken: localStorage.getItem('refreshToken'),
            grecaptcha,
          })
        ).data.data
        Store.dispatch('setLogin', response)
        return _axios(originalRequest)
      } catch (err) {
        Store.dispatch('setLogout')
        if (Router.currentRoute.name !== 'login') {
          Router.push({ name: 'login', params: { expired: true } })
        }
      }
    }
    Store.commit('setLoading', false)

    return Promise.reject(error)
  },
)
_axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })

export default _axios
