import api from '../../plugins/axios'

const base = 'auth/'

export default {
  loginBearer (data) {
    return api.post(`${base}login-bearer`, data)
  },
  login (data) {
    return api.post(`${base}login`, data)
  },
}
